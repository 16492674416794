import { createContext, useContext, useEffect, useState } from "react";

/* Imgs */
import poloImg from "../assets/molelos/hero.png";

/* Imgs */
import polocard from "../assets/molelos/polocard.png";
import virtuscard from "../assets/molelos/virtuscard.png";
import tcrosscard from "../assets/molelos/tcrosscard.png";
import amarokcard from "../assets/molelos/amarokcard.png";
import taoscard from "../assets/molelos/taoscard.png";
import saveirocard from "../assets/molelos/saveirocard.png";
import nivuscard from "../assets/molelos/nivuscard.png";

/* Galeria */
/* Polo */
import poloGal1 from "../assets/molelos/polo/1.jpeg";
import poloGal2 from "../assets/molelos/polo/2.jpeg";
import poloGal3 from "../assets/molelos/polo/3.jpeg";
import poloGal4 from "../assets/molelos/polo/4.jpeg";
import poloGal5 from "../assets/molelos/polo/5.jpeg";
import poloGal6 from "../assets/molelos/polo/6.jpeg";

/* Virtus */
import virtusImg from "../assets/molelos/virtus/virtusImg.png";
import virtusGal1 from "../assets/molelos/virtus/1.jpeg";
import virtusGal2 from "../assets/molelos/virtus/2.jpeg";
import virtusGal3 from "../assets/molelos/virtus/3.jpeg";
import virtusGal4 from "../assets/molelos/virtus/4.jpeg";
import virtusGal5 from "../assets/molelos/virtus/5.jpeg";

/* T-Cross */
import tcrossImg from "../assets/molelos/tcross/tcrossImg.jpeg";
import tcrossGal1 from "../assets/molelos/tcross/1.jpeg";
import tcrossGal2 from "../assets/molelos/tcross/2.jpeg";
import tcrossGal3 from "../assets/molelos/tcross/3.jpeg";
import tcrossGal4 from "../assets/molelos/tcross/4.jpeg";
import tcrossGal5 from "../assets/molelos/tcross/5.jpeg";

/* Amarok */
import amarokImg from "../assets/molelos/amarok/amarokImg.jpeg";
import amarokGal1 from "../assets/molelos/amarok/1.jpeg";
import amarokGal2 from "../assets/molelos/amarok/2.jpeg";
import amarokGal3 from "../assets/molelos/amarok/3.jpeg";
import amarokGal4 from "../assets/molelos/amarok/4.jpeg";
import amarokGal5 from "../assets/molelos/amarok/5.jpeg";
import amarokGal6 from "../assets/molelos/amarok/6.jpeg";

/* Taos */
import taosImg from "../assets/molelos/taos/taosImg.jpeg";
import taosGal1 from "../assets/molelos/taos/1.jpeg";
import taosGal2 from "../assets/molelos/taos/2.jpeg";
import taosGal3 from "../assets/molelos/taos/3.jpeg";

/* Saveiro */
import saveiroImg from "../assets/molelos/saveiro/saveiroImg.jpeg";
import saveiroGal1 from "../assets/molelos/saveiro/1.jpeg";
import saveiroGal2 from "../assets/molelos/saveiro/2.jpeg";
import saveiroGal3 from "../assets/molelos/saveiro/3.jpeg";
import saveiroGal4 from "../assets/molelos/saveiro/4.jpeg";
import saveiroGal5 from "../assets/molelos/saveiro/5.jpeg";

/* Nivus */
import nivusImg from "../assets/molelos/nivus/nivusImg.webp";
import nivusGal1 from "../assets/molelos/nivus/1.jpg";
import nivusGal2 from "../assets/molelos/nivus/2.jpeg";
import nivusGal3 from "../assets/molelos/nivus/3.jpg";

export const ConsContext = createContext();

export const useCons = () => {
  const context = useContext(ConsContext);
  if (!context) throw new Error("useCons must be used within a ConsContext");
  return context;
};

export const ConsContextProvider = ({ children }) => {
  const [popActive, setPopActive] = useState({
    active: false,
    clickModel: "",
  });

  const buttonData = (active, clickModel, modelName) => {
    setPopActive({
      active: active,
      clickModel: clickModel ? clickModel : "",
    });
    try {
      dataLayer.push({
        event: "CTA",
        model: modelName ? modelName : "Header",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const prices = {
    polo: {
      cuota: "248.270",
      anticipo: "8.424.360",
      valor: "28.081.199",
    },
    virtus: {
      cuota: "-",
      anticipo: "-",
      valor: "-",
    },
    tcross: {
      cuota: "401.169",
      anticipo: "10.215.210",
      valor: "34.050.700",
    },
    amarok: {
      cuota: "342.497",
      anticipo: "16.548.260",
      valor: "41.370.650",
    },
    taos: {
      cuota: "350.859",
      anticipo: "16.952.320",
      valor: "42.380.800",
    },
    saveiro: {
      cuota: "-",
      anticipo: "-",
      valor: "-",
    },
    nivus: {
      cuota: "373.409",
      anticipo: "9.508.350",
      valor: "31.694.500",
    },
  };

  const modelos = [
    {
      id: 1,
      link: "plan-polo",
      nombre: "Polo Track",
      modelo: "Polo",
      color: "#a41716",
      text: "white",
      cuota: prices.polo.cuota,
      plan: "Plan 70/30",
      tenelo: `Tenelo con $${prices.polo.anticipo}`,
      text1: `Tomamos tu usado como anticipo`,
      text2: "Podés financiar hasta en 84 cuotas",
      text3: `Valor de la Unidad: $${prices.polo.valor}`,
      img: poloImg,
      galeria: [poloGal1, poloGal2, poloGal3, poloGal4, poloGal5, poloGal6],
      card: polocard,
      cupo: 6,
      dataButton: "PLAN POLO",
      disabled: false,
    },
    /* {
      id: 2,
      link: "plan-virtus",
      nombre: "Virtus",
      modelo: "Virtus",
      color: "#afb5b9",
      text: "white",
      cuota: prices.virtus.cuota,
      plan: "Financiá hasta el 100%",
      text1: `Tomamos tu usado como anticipo`,
      text2: "Opcional: Adjudicado",
      text3: `Valor de la Unidad: $${prices.virtus.valor}`,
      img: virtusImg,
      galeria: [virtusGal1, virtusGal2, virtusGal3, virtusGal4, virtusGal5],
      card: virtuscard,
      cupo: 3,
      dataButton: "PLAN VIRTUS",
      disabled: false,
    }, */
    {
      id: 3,
      link: "plan-tcross",
      nombre: "T-Cross",
      modelo: "T-Cross",
      color: "#472027",
      text: "white",
      cuota: prices.tcross.cuota,
      plan: "Plan 70/30",
      tenelo: `Tenelo con $${prices.tcross.anticipo}`,
      text1: `Tomamos tu usado como anticipo`,
      text2: "Podés financiar hasta en 84 cuotas",
      text3: `Valor de la Unidad: $${prices.tcross.valor}`,
      img: tcrossImg,
      galeria: [tcrossGal1, tcrossGal2, tcrossGal3, tcrossGal4, tcrossGal5],
      card: tcrosscard,
      cupo: 2,
      dataButton: "PLAN T-CROSS",
      disabled: true,
    },
    {
      id: 4,
      link: "plan-amarok",
      nombre: "Amarok",
      modelo: "Amarok",
      color: "#969696",
      text: "black",
      cuota: prices.amarok.cuota,
      plan: "Plan 60/40",
      tenelo: `Tenelo con $${prices.amarok.anticipo}`,
      text1: `Tomamos tu usado como anticipo`,
      text2: "Podés financiar hasta en 84 cuotas",
      text3: `Valor de la Unidad: $${prices.amarok.valor}`,
      img: amarokImg,
      galeria: [
        amarokGal1,
        amarokGal2,
        amarokGal3,
        amarokGal4,
        amarokGal5,
        amarokGal6,
      ],
      card: amarokcard,
      cupo: 4,
      dataButton: "PLAN AMAROK",
      disabled: false,
    },
    {
      id: 5,
      link: "plan-taos",
      nombre: "Taos",
      modelo: "Taos",
      color: "#dedede",
      text: "black",
      cuota: prices.taos.cuota,
      plan: "Plan 60/40",
      tenelo: `Tenelo con $${prices.taos.anticipo}`,
      text1: `Tomamos tu usado como anticipo`,
      text2: "Podés financiar hasta en 84 cuotas",
      text3: `Valor de la Unidad: $${prices.taos.valor}`,
      img: taosImg,
      galeria: [taosGal1, taosGal2, taosGal3],
      card: taoscard,
      cupo: 6,
      dataButton: `PLAN TAOS`,
      disabled: false,
    },
    /* {
      id: 6,
      link: "plan-saveiro",
      nombre: "Saveiro",
      modelo: "Saveiro",
      color: "#002f63",
      text: "white",
      cuota: prices.saveiro.cuota,
      plan: "Financiá hasta el 90%",
      text1: `Tomamos tu usado como anticipo`,
      text2: "Opcional: Adjudicado",
      text3: `Valor de la Unidad: $${prices.saveiro.valor}`,
      img: saveiroImg,
      galeria: [
        saveiroGal1,
        saveiroGal2,
        saveiroGal3,
        saveiroGal4,
        saveiroGal5,
      ],
      card: saveirocard,
      cupo: 4,
      dataButton: "PLAN SAVEIRO",
      disabled: false,
    }, */
    {
      id: 7,
      link: "plan-nivus",
      nombre: "Nivus",
      modelo: "Nivus",
      color: "#242527",
      text: "white",
      cuota: prices.nivus.cuota,
      plan: "Plan 60/40",
      tenelo: `Tenelo con $${prices.nivus.anticipo}`,
      text1: `Tomamos tu usado como anticipo`,
      text2: "Podés financiar hasta en 84 cuotas",
      text3: `Valor de la Unidad: $${prices.nivus.valor}`,
      img: nivusImg,
      galeria: [nivusGal1, nivusGal2, nivusGal3],
      card: nivuscard,
      cupo: 3,
      dataButton: "PLAN NIVUS",
      disabled: false,
    },
  ];

  const modelsOptions = modelos.map((model) => {
    return {
      value: model.dataButton,
      text: `${model.dataButton} (Cuota $${model.cuota})`,
      disabled: model.disabled,
    };
  });

  console.log(modelsOptions);

  return (
    <ConsContext.Provider
      value={{
        popActive,
        setPopActive,
        prices,
        buttonData,
        modelos,
        modelsOptions,
      }}
    >
      {children}
    </ConsContext.Provider>
  );
};
